<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addDocumentFooter') : $t('message.updateDocumentFooter') + ": " + documentFooter }}</span>
                <HelpPopUpV2 help-page-component="AddDocumentFooter" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="documentFooterForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.footerText') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.paragraph]"
                                    :value="text"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="text = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.document') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-select
                                    :items="documentTypes"
                                    :rules="[...validationRules.required]"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="type"
                            >
                                <template v-slot:item="{item}">
                                    {{ capitalize(item.text) }}
                                </template>
                                <template v-slot:selection="{item}">
                                    {{ capitalize(item.text) }}
                                </template>
                            </v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.id') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.number, ...validationRules.minimumOne]"
                                    :value="id"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="id = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.placeholder') }}</v-flex>
                        <v-flex xs6 lg8 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.variable]"
                                    :value="replace"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="replace = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addDocumentFooter()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveDocumentFooter()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {capitalize, isObject} from "Helpers/helpers"

const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddDocumentFooter",
    components: {HelpPopUpV2},
    props: ['documentFooterId','documentFooter','documentFooterCategory','dialog','updateMode'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            documentTypes: [
                {value: 'altinvoice', text: 'altinvoice'},
                {value: 'contract', text: 'contract'},
                {value: 'covernote', text: 'covernote'},
                {value: 'invoice', text: 'invoice'},
                {value: 'lcacondition', text: 'lcacondition'},
                {value: 'lcanote', text: 'lcanote'},
                {value: 'localsalesinvoice', text: 'localsalesinvoice'},
                {value: 'purchaseorder', text: 'purchaseorder'},
                {value: 'salesorder', text: 'salesorder'}
            ],
            loading: {
                add: false,
                get: false,
                save: false
            },
            edit_dialog: false,
            rules: {
                document: v => !!v || this.$t('message.required'),
                id: v => !!v || this.$t('message.required'),
                title: v => !!v || this.$t('message.required')
            },
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('documentfooter', {
            id: 'id',
            item_id: 'item_id',
            sort_id: 'sort_id',
            replace: 'replace',
            text: 'text',
            type: 'type'
        },'statePrefix'),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('documentfooter', {
            createDocumentFooter: 'createDocumentFooter',
            getDocumentFooterToUpdateById: 'getDocumentFooterToUpdateById',
            resetDocumentFooterToUpdate: 'resetDocumentFooterToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateDocumentFooter: 'updateDocumentFooter'
        }),
        addDocumentFooter() {
            if(this.$refs.documentFooterForm.validate()){
                this.loading.add = true
                this.createDocumentFooter()
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.documentFooterAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.documentFooterNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.documentFooterNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.documentFooterNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.documentFooterNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        capitalize,
        saveDocumentFooter() {
            if(this.$refs.documentFooterForm.validate()){
                this.loading.save = true
                this.updateDocumentFooter()
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.documentFooterUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.documentFooterNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.documentFooterNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.documentFooterNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.documentFooterNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getDocumentFooterToUpdateById(this.documentFooterId)
                        .then(() => {
                            this.loading.get = false
                        })
                        .catch(() => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetDocumentFooterToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    }
}
</script>

<style scoped>

</style>