import { render, staticRenderFns } from "./AddDocumentFooter.vue?vue&type=template&id=3256ecce&scoped=true&"
import script from "./AddDocumentFooter.vue?vue&type=script&lang=js&"
export * from "./AddDocumentFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3256ecce",
  null
  
)

export default component.exports